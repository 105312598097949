import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import Switch from 'react-switch'
import AdminLayout from "../../layouts/AdminLayout";
import {
  useFetchAuthorisation,
  useFetchUserData
} from "../../utils/AppFunctions";
import { MdAdd } from "react-icons/md";
import FileUploadField from "../../components/FileUploadField";
import config from "../../app-config";
import notify from "../../utils/IANotification";

export default function LibraryFormPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();
  const [libraryItemData, setLibraryItemData] = useState({
    isbn: "",
    title: "",
    subtitle: "",
    description: "",
    author: "",
    publisher: "",
    media_type: "",
    limit_borrowing: "",
    media_url: "",
    file_id: "",
    is_published: 0,
    is_loanable: 0,
  })

  const libray_id = new URLSearchParams(window.location.search).get("library_id");

  useEffect(()=>{
    fetchLibraryItemById()
  },[])

  const fetchLibraryItemById = async () => {
    if (libray_id === "new") return
    let endpoint = `${config.endpoint}/library/id/${libray_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setLibraryItemData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const saveLibraryItem = async () => {
    let endpoint = `${config.endpoint}/library/id/${libray_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(libraryItemData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Library item saved successfully", type: "success" })
      navigate("/admin/library")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const createLibraryItem = async () => {
    let endpoint = `${config.endpoint}/library`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(libraryItemData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Library item saved successfully", type: "success" })
      navigate("/admin/library")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteLibraryItem = async () => {
    let endpoint = `${config.endpoint}/library/id/${libray_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Library item deleted successfully", type: "success" })
      navigate("/admin/library")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  return (
    <AdminLayout navSelected={"Library Items"}>
      <div className="flex flex-row items-start gap-2">
        <div className="w-full">
          <div className="card">
            <p className="card-title">Digital Library</p>
            <div className="flex flex-row items-center gap-2">
              <div className="input-container w-6/12">
                <label htmlFor="" className="input-label">ISBN</label>
                <input type="text" className="text-input" value={libraryItemData.isbn} onChange={(e) => setLibraryItemData({ ...libraryItemData, isbn: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Title</label>
                <input type="text" className="text-input" value={libraryItemData.title} onChange={(e) => setLibraryItemData({ ...libraryItemData, title: e.target.value })} />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Subtitle</label>
              <input type="text" className="text-input" value={libraryItemData.subtitle} onChange={(e) => setLibraryItemData({ ...libraryItemData, subtitle: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Description</label>
              <textarea className="textarea-input" value={libraryItemData.description} onChange={(e) => setLibraryItemData({ ...libraryItemData, description: e.target.value })} />
            </div>
          </div>
          <div className="mt-2 card">
            <p className="card-title">Publishing</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Author</label>
              <input type="text" className="text-input" value={libraryItemData.author} onChange={(e) => setLibraryItemData({ ...libraryItemData, author: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Publisher</label>
              <input type="text" className="text-input" value={libraryItemData.publisher} onChange={(e) => setLibraryItemData({ ...libraryItemData, publisher: e.target.value })} />
            </div>
            <div className="mt-2 flex flex-row items-center">

            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="card">
            <p className="card-title">Media</p>
            {libray_id !== "new" &&
              <FileUploadField
                max_files={1}
                max_file_size={1}
                text={"Upload Media"}
                subtext={"Upload a media file"}
                upload_url={`${config.endpoint}/file/library/id/${libray_id}`}
                onChange={()=>{}}
              />
            }
            <div className="input-container">
              <label htmlFor="" className="input-label">Media URL</label>
              <input type="text" className="text-input" placeholder="e.g. https://media.com" value={libraryItemData.media_url} onChange={(e) => setLibraryItemData({ ...libraryItemData, media_url: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Type</label>
              <select name="" id="" className="select-input"
                value={libraryItemData.media_type}
                onChange={(e) => setLibraryItemData({ ...libraryItemData, media_type: e.target.value })}
              >
                <option value="">Select media type</option>
                <option value="audio">Audio</option>
                <option value="video">Video</option>
                <option value="pdf">PDF</option>
                <option value="ebook">eBook</option>
                <option value="book">Book</option>
                <option value="article">Article</option>
              </select>
            </div>
          </div>
          <div className="mt-2 card">
            <p className="card-title">Options</p>
            <div className="flex flex-row items-center gap-2">
              <div className="input-container">
                <label htmlFor="" className="input-label">Limit Borrowing</label>
                <input type="number" placeholder="Number of digital copies available" className="text-input" value={libraryItemData.limit_borrowing} onChange={(e) => setLibraryItemData({ ...libraryItemData, limit_borrowing: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Published</label>
                <Switch
                  checked={libraryItemData?.is_published == 1}
                  onChange={(e) => setLibraryItemData({ ...libraryItemData, is_published: e ? 1 : 0 })}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Allowed borrowing</label>
                <Switch
                  checked={libraryItemData?.is_loanable == 1}
                  onChange={(e) => setLibraryItemData({ ...libraryItemData, is_loanable: e ? 1 : 0 })}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end mt-2 gap-2">
            {libray_id !== "new" &&
              <button className="button-cta" onClick={deleteLibraryItem}>Delete</button>
            }
            <button className="button" onClick={libray_id === "new" ? createLibraryItem : saveLibraryItem}>Save</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}