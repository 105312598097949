import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./globals.css";
import IndexPage from "./pages/IndexPage";
import About from "./pages/About";
import StaffMembers from "./pages/StaffMembers";
import MissionRelease from "./pages/MissionRelease";
import GettingToBMC from "./pages/GettingToBMC";
import OnlineServices from "./pages/OnlineServices";
import OnlineServicesTraditional from "./pages/OnlineServicesTraditional";
import OnlineServicesChinese from "./pages/OnlineServicesChinese";
import SermonSeries from "./pages/SermonSeries";
import SermonSeriesMatthew from "./pages/SermonSeriesMatthew";
import ContactUs from "./pages/ContactUs";
import MissionsOpportunities from "./pages/MissionsOpportunities";
import NotFound from "./pages/NotFound";

import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/admin/HomePage";
import MissionsGlobePage from "./pages/user/MissionsGlobePage";
import SettingsPage from "./pages/admin/SettingsPage";
import CommerceProductsPage from "./pages/admin/CommerceProductsPage";
import ServicesPage from "./pages/admin/ServicesPage";
import CommerceProductViewPage from "./pages/admin/CommerceProductViewPage";
import ServicesFormPage from "./pages/admin/ServicesFormPage";
import AnnouncementPage from "./pages/admin/AnnouncementPage";
import AnnouncementFormPage from "./pages/admin/AnnouncementFormPage";
import MembersPage from "./pages/admin/MembersPage";
import MembersFormPage from "./pages/admin/MembersFormPage";
import DiscipleGroupPage from "./pages/admin/DiscipleGroupPage";
import DiscipleGroupFormPage from "./pages/admin/DiscipleGroupFormPage";
import DiscipleGroupClustersPage from "./pages/admin/DiscipleGroupClustersPage";
import DiscipleGroupClustersFormPage from "./pages/admin/DiscipleGroupClustersFormPage";
import PlanPage from "./pages/admin/PlanPage";
import PlanFormPage from "./pages/admin/PlanFormPage";
import PrayerPage from "./pages/admin/PrayerPage";
import PrayerFormPage from "./pages/admin/PrayerFormPage";
import MinistryPage from "./pages/admin/MinistryPage";
import MinistryFormPage from "./pages/admin/MinistryFormPage";
import MissionsPage from "./pages/admin/MissionsPage";
import MissionsFormPage from "./pages/admin/MissionsFormPage";
import TithingPage from "./pages/admin/TithingPage";
import TithingStatusPage from "./pages/public/TithingStatusPage";
import BulletinPage from "./pages/admin/BulletinPage";
import BulletinFormPage from "./pages/admin/BulletinFormPage";
import EventPage from "./pages/admin/EventPage";
import EventFormPage from "./pages/admin/EventFormPage";
import SpecialServicesPage from "./pages/admin/SpecialServicesPage";
import SpecialServicesFormPage from "./pages/admin/SpecialServicesFormPage";
import ContactPage from "./pages/admin/ContactPage";
import ResourcesFormPage from "./pages/admin/ResourcesFormPage";
import ResourcesPage from "./pages/admin/ResourcesPage";
import PrayerSupportPage from "./pages/admin/PrayerSupportPage";
import AdminCafePage from "./pages/admin/AdminCafePage";
import AdminCafeFormPage from "./pages/admin/AdminCafeFormPage";
import Whatsapp from "./pages/Whatsapp";
import Tithes from "./pages/Tithes";
import PrayerSupport from "./pages/PrayerSupport";
import Promote from "./pages/Promote";
import New from "./pages/New";
import AllMinistries from "./pages/AllMinistries";
import FeaturedItem from "./pages/FeaturedItem";
import WhistleBlowingPolicy from "./pages/WhistleBlowingPolicy";
import SiteMap from "./pages/SiteMap";
import Announcements from "./pages/Announcements";
import News from "./pages/News";
import AudioPage from "./pages/admin/AudioPage";
import AudioFormPage from "./pages/admin/AudioFormPage";
import GalleryPage from "./pages/admin/GalleryPage";
import MemberHomePage from "./pages/user/MemberHomePage";
import LibraryPage from "./pages/admin/LibraryPage";
import LibraryFormPage from "./pages/admin/LibraryFormPage";
import ContactFormPage from "./pages/admin/ContactFormPage";
import LibraryLoanRecordsPage from "./pages/admin/LibraryLoanRecordsPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public */}
        <Route path="/" element={<AuthPage pageState={"login"} />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/our-pastors-staff" element={<StaffMembers />} />
        <Route path="/missions-release" element={<MissionRelease />} />
        <Route path="/getting-here" element={<GettingToBMC />} />
        <Route path="/online-services" element={<OnlineServices />} />
        <Route path="/sermon-series" element={<SermonSeries />} />
        <Route path="/sermon-series/gospel-of-matthew" element={<SermonSeriesMatthew />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/whatsapp" element={<Whatsapp />} />
        <Route path="/traditional" element={<OnlineServicesTraditional />} />
        <Route path="/chinese" element={<OnlineServicesChinese />} />
        <Route path="/tithes" element={<Tithes />} />
        <Route path="/bmcplus" element={<Promote />} />
        <Route path="/missions-opportunities" element={<MissionsOpportunities />} />
        <Route path="/new" element={<New />} />
        <Route path="/ministries" element={<AllMinistries />} />
        <Route path="/featured-item/:item" element={<FeaturedItem />} />
        <Route path="/whistle-blowing-policy" element={<WhistleBlowingPolicy />} />
        <Route path="/site-map" element={<SiteMap />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/news/:topic" element={<News />} /> */}


        <Route path="/auth/login" element={<AuthPage pageState={"login"} />} />
        <Route path="/auth/register" element={<AuthPage pageState={"register"} />} />
        <Route path="/auth/recovery" element={<AuthPage pageState={"recovery"} />} />
        <Route path="/auth/recovery/verify" element={<AuthPage pageState={"reset"} />} />
        <Route path="/tithing" element={<TithingStatusPage />} />
        <Route path="/prayer-requests" element={<PrayerSupport />} />

        {/* Missions */}
        <Route path="/missions/globe" element={<MissionsGlobePage />} />

        {/* User */}
        <Route path="/home" element={<MemberHomePage />} />


        {/* Admin Dashboard */}
        <Route path="/admin/library" element={<LibraryPage />} />
        <Route path="/admin/library/loan" element={<LibraryLoanRecordsPage />} />
        <Route path="/admin/library/details" element={<LibraryFormPage />} />
        <Route path="/admin/missions" element={<MissionsPage />} />
        <Route path="/admin/missions/details" element={<MissionsFormPage />} />
        <Route path="/admin" element={<HomePage />} />
        <Route path="/admin/settings" element={<SettingsPage />} />
        <Route path="/admin/commerce/products" element={<CommerceProductsPage />} />
        <Route path="/admin/commerce/products/:id" element={<CommerceProductViewPage />} />
        <Route path="/admin/services" element={<ServicesPage />} />
        <Route path="/admin/services/details" element={<ServicesFormPage />} />
        <Route path="/admin/contact" element={<ContactPage />} />
        <Route path="/admin/contact/details" element={<ContactFormPage />} />
        <Route path="/admin/special/services" element={<SpecialServicesPage />} />
        <Route path="/admin/special/services/details" element={<SpecialServicesFormPage />} />
        <Route path="/admin/events" element={<EventPage />} />
        <Route path="/admin/events/details" element={<EventFormPage />} />
        <Route path="/admin/bulletin" element={<BulletinPage />} />
        <Route path="/admin/bulletin/details" element={<BulletinFormPage />} />
        <Route path="/admin/tithing" element={<TithingPage />} />
        <Route path="/admin/announcements" element={<AnnouncementPage />} />
        <Route path="/admin/announcements/details" element={<AnnouncementFormPage />} />
        <Route path="/admin/plan" element={<PlanPage />} />
        <Route path="/admin/plan/details" element={<PlanFormPage />} />
        <Route path="/admin/prayer" element={<PrayerPage />} />
        <Route path="/admin/prayer/details" element={<PrayerFormPage />} />
        <Route path="/admin/prayer/support" element={<PrayerSupportPage />} />
        <Route path="/admin/users" element={<MembersPage />} />
        <Route path="/admin/users/details" element={<MembersFormPage />} />
        <Route path="/admin/ministry" element={<MinistryPage />} />
        <Route path="/admin/ministry/details" element={<MinistryFormPage />} />
        <Route path="/admin/audio" element={<AudioPage />} />
        <Route path="/admin/audio/details" element={<AudioFormPage />} />
        <Route path="/admin/gallery" element={<GalleryPage />} />
        <Route path="/admin/dg" element={<DiscipleGroupPage />} />
        <Route path="/admin/dg/details" element={<DiscipleGroupFormPage />} />
        <Route path="/admin/dg/clusters" element={<DiscipleGroupClustersPage />} />
        <Route path="/admin/dg/clusters/details" element={<DiscipleGroupClustersFormPage />} />
        <Route path="/admin/resources" element={<ResourcesPage />} />
        <Route path="/admin/resources/details" element={<ResourcesFormPage />} />
        <Route path="/admin/cafe" element={<AdminCafePage />} />
        <Route path="/admin/cafe/details" element={<AdminCafeFormPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
