import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight, FaCross, FaMoneyBill, FaPray, FaPrayingHands } from 'react-icons/fa'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdBook, MdChurch, MdEditDocument, MdFastfood, MdGroup, MdLibraryAdd, MdLibraryBooks, MdMonetizationOn, MdMoney, MdMusicNote, MdOutlineAddTask, MdOutlineAirplanemodeActive, MdOutlineAnnouncement, MdOutlineArticle, MdOutlineChurch, MdOutlineEvent, MdOutlineEventNote, MdOutlineGroup, MdOutlineGroupWork, MdOutlineGroups2, MdOutlineHelp, MdOutlineHelpCenter, MdOutlineMiscellaneousServices, MdOutlineMoney, MdOutlineMoneyOff, MdOutlineMusicNote, MdOutlinePhotoAlbum, MdOutlineSettings, MdOutlineShop, MdOutlineShop2, MdOutlineShoppingCart, MdOutlineTravelExplore } from 'react-icons/md'
import { FiAward } from 'react-icons/fi'
import config from '../app-config'
import { isMobile } from '../utils/helper'

export default function AdminNavbar({ selected }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 20
    const color = "white"
    const navigate = useNavigate()

    const [hoverItem, setHoverItem] = useState("")

    const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
    const [navbarWidth, setNavbarWidth] = useState(document.getElementById("navbar")?.clientWidth)
    const [selectedTop, setSelectedTop] = useState(0)
    const [selectedMainCategory, setSelectedMainCategory] = useState("")

    useLayoutEffect(() => {
        setHeader(document.getElementById("user-header")?.clientHeight)
        setNavbarWidth(document.getElementById("navbar")?.clientWidth)
    }, [header, navbarWidth])


    let items = [
        {
            name: config.client_name,
            authorisation: "staff",
            children: [
                {
                    name: "Dashboard",
                    icon: <FaCross size={size} color={color} />,
                    link: "/admin",
                    authorisation: "staff",
                },
                {
                    name: "Ministry",
                    icon: <MdMusicNote size={size} color={color} />,
                    link: "/admin/ministry",
                },
                // {
                //     name: "Tithing",
                //     icon: <FaMoneyBill size={size} color={color} />,
                //     link: "/admin/tithing",
                // },
                {
                    name: "Cafe",
                    icon: <MdFastfood size={size} color={color} />,
                    link: "/admin/cafe",
                    authorisation: "staff",
                },
                {
                    name: "Settings",
                    icon: <MdOutlineSettings size={size} color={color} />,
                    link: "/admin/settings?state=general",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Service Management",
            authorisation: "staff",
            children: [
                {
                    name: "Services",
                    icon: <MdOutlineChurch size={size} color={color} />,
                    link: "/admin/services",
                    authorisation: "staff",
                },
                {
                    name: "Special Services",
                    icon: <MdOutlineChurch size={size} color={color} />,
                    link: "/admin/special/services",
                    authorisation: "staff",
                },
                // {
                //     name: "Attendance",
                //     icon: <MdOutlineGroups2 size={size} color={color} />,
                //     link: "/admin/users",
                //     authorisation: "staff",
                // },
                {
                    name: "Contact Form",
                    icon: <MdOutlineArticle size={size} color={color} />,
                    link: "/admin/contact",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Resources",
            authorisation: "staff",
            children: [
                {
                    name: "Plans & Materials",
                    icon: <MdBook size={size} color={color} />,
                    link: "/admin/plan",
                    authorisation: "staff",
                },
                {
                    name: "Resources",
                    icon: <MdBook size={size} color={color} />,
                    link: "/admin/resources",
                    authorisation: "staff",
                },
                {
                    name: "Bulletin",
                    icon: <MdOutlineArticle size={size} color={color} />,
                    link: "/admin/bulletin",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Library",
            authorisation: "staff",
            children: [
                {
                    name: "Library Items",
                    icon: <MdLibraryBooks size={size} color={color} />,
                    link: "/admin/library",
                    authorisation: "staff",
                },
                {
                    name: "Borrowed Items",
                    icon: <MdLibraryBooks size={size} color={color} />,
                    link: "/admin/library/loan",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Prayer",
            authorisation: "staff",
            children: [
                {
                    name: "Corporate Prayer",
                    icon: <FaPrayingHands size={size} color={color} />,
                    link: "/admin/prayer",
                    authorisation: "staff",
                },
                {
                    name: "Prayer Support",
                    icon: <FaPrayingHands size={size} color={color} />,
                    link: "/admin/prayer/support",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Events",
            authorisation: "staff",
            children: [
                {
                    name: "Events",
                    icon: <MdOutlineEvent size={size} color={color} />,
                    link: "/admin/events",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "ServOps",
            authorisation: "staff",
            children: [
                {
                    name: "Missions",
                    icon: <MdOutlineTravelExplore size={size} color={color} />,
                    link: "/admin/missions",
                    authorisation: "staff",
                },
            ]
        },
        // {
        //     name: "BMC Shop",
        //     authorisation: "staff",
        //     children: [
        //         {
        //             name: "Products",
        //             icon: <MdOutlineShop2 size={size} color={color} />,
        //             link: "/admin/commerce/products",
        //             authorisation: "staff",
        //         },
        //         {
        //             name: "Orders",
        //             icon: <MdOutlineShoppingCart size={size} color={color} />,
        //             link: "/admin/commerce/orders",
        //             authorisation: "staff",
        //         },
        //     ]
        // },
        {
            name: "News & Annnouncements",
            authorisation: "staff",
            children: [
                {
                    name: "Announcements",
                    icon: <MdOutlineAnnouncement size={size} color={color} />,
                    link: "/admin/announcements",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Media & Gallery",
            authorisation: "staff",
            children: [
                {
                    name: "Photo Gallery",
                    icon: <MdOutlinePhotoAlbum size={size} color={color} />,
                    link: "/admin/gallery",
                    authorisation: "staff",
                },
                {
                    name: "Audio & Worship",
                    icon: <MdOutlineMusicNote size={size} color={color} />,
                    link: "/admin/audio",
                    authorisation: "staff",
                },
            ]
        },
        {
            name: "Users",
            authorisation: "staff",
            children: [
                {
                    name: "Members",
                    icon: <MdOutlineGroup size={size} color={color} />,
                    link: "/admin/users",
                    authorisation: "staff",
                },
                {
                    name: "DG Clusters",
                    icon: <MdOutlineGroupWork size={size} color={color} />,
                    link: "/admin/dg/clusters",
                    authorisation: "staff",
                },
                {
                    name: "Disciple Groups",
                    icon: <MdOutlineGroups2 size={size} color={color} />,
                    link: "/admin/dg",
                    authorisation: "staff",
                },
                // {
                //     name: "Consent Forms",
                //     icon: <MdOutlineArticle size={size} color={color} />,
                //     link: "/admin/consent-forms",
                //     authorisation: "staff",
                // },
            ]
        },
    ]
    return (
        <div className='navbar flex-col flex justify-between items-start max-h-dvh max-w-[300px] z-[1000]' id='navbar' style={{ position: isMobile(window.innerWidth) ? "fixed" : "unset" }}>
            <div>
                <img src="/logoWhite.png" alt="" style={{ width: "100%", height: 100, objectFit: "contain", borderBottom: "1px solid gray", marginBottom: 20 }} id='logo-navbar' />
                {/* <div style={{ height: header - 10 }} /> */}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
                    {items.map((item, index) => {
                        if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return null
                        return (
                            <div className='navbarItem w-100' key={index} id={`navitem-${index}`}>
                                <p className="small white mt-4 cursor-pointer" style={{ letterSpacing: 1.2, fontWeight: item.children?.map(child => child.name).includes(selected) ? "900" : "normal" }}
                                    onClick={() => {
                                        setSelectedMainCategory(item.name)
                                    }}
                                >{item.name.toUpperCase()}</p>
                                {item.children.map((child, index) => {
                                    if (!authorisationConverter({ requirement: child?.authorisation, current_role: userRole })) return null
                                    if (item.name !== selectedMainCategory && !item.children?.map(child => child.name).includes(selected)) return null
                                    return (
                                        <div key={index} id={`navitem-child-${index}`}
                                            style={{
                                                backgroundColor: selected === child.name ? "rgba(255,255,255,0.2)" : "",
                                                paddingBlock: 10,
                                                width: "100%",
                                            }}
                                            className='navbarItemChild flex-row items-center flex gap-2 cursor-pointer'
                                            onClick={() => {
                                                navigate(child.link)
                                            }}
                                        >
                                            <div style={{ paddingLeft: selected === child.name ? 20 : 0 }}>
                                                {child.icon}
                                            </div>
                                            <p className="small white">{child.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
            <p className="text-xs text-gray-600 pb-4">Built by <span className="hover:underline hover:cursor-pointer"
                onClick={() => {
                    window.open("https://ourcodelab.com")
                }}
            >OurCodeLab LLP</span> © {new Date().getFullYear()}</p>
        </div>
    )
}
