import React from 'react'
import config from '../app-config'

export default function MainHeader() {
  return (
    <div className="px-5 py-4 bg-white p-3">
        <img src={"/app/logo_teal.png"} alt="" style={{objectFit:"contain", width:"auto", height:100, }}/>
        {/* <h1 className="text-2xl font-black">{config.client_app}</h1>
        <label htmlFor="" className="text-sm">by <span className='font-bold'>{config.client_name}</span></label> */}
        <p className="text-gray-400 text-xs">Your faithful gateway to digital church management</p>
    </div>
  )
}
